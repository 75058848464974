import { EventLabel } from 'common/ontology'
import { Range } from 'common/types'
import { BaseLabels } from '../../components/Labels'
import { TimeStampContainer } from './Event'
import { useEventContext } from './EventProvider'

const EVENT_LABEL_TEXT: Record<EventLabel, string> = {
  chute: 'Chute',
  vomissement: 'Vomissement',
  sortie: 'Sortie de chambre',
}

function abruptStartDescription(
  description: string,
  startDescription: string,
  endDescription: string,
) {
  return (
    <>
      <>{description}. </>
      <b>DEBUT</b>
      <> : {startDescription} | </>
      <b>FIN</b>
      <> : {endDescription}.</>
    </>
  )
}

const EVENT_LABEL_DESCRIPTION: Record<EventLabel, string | JSX.Element> = {
  sortie: abruptStartDescription(
    'Le résident est sortie seul de sa chambre',
    'Quand le résident sort',
    'Quand le résident revient',
  ),
  chute: abruptStartDescription(
    'Le résident a chuté',
    'Quand le résident chute',
    'Quand le résident se relève',
  ),
  vomissement: abruptStartDescription(
    'Le résident a vomi',
    'Quand le résident a vomi une première fois',
    'Quand le résident arrête de vomir (considérer les vomissements espacés de quelques secondes comme un seul évènement)',
  ),
}

const EVENT_LABELS: { title: string; labels: EventLabel[] }[] = [
  {
    title: 'Évènements soudains',
    labels: ['chute', 'vomissement', 'sortie'],
  },
]

type EventLabelsProps = {
  showDescriptions: boolean
  range: Range
}

export const EventLabels = ({ showDescriptions, range }: EventLabelsProps) => {
  const { selectedLabel, setEditedPhase, setSelectedLabel } = useEventContext()

  const handleLabelClick = (label: EventLabel) => {
    if (selectedLabel === label) {
      setSelectedLabel(null)
      setEditedPhase(null)
    } else {
      setSelectedLabel(label)
      setEditedPhase({ label })
    }
  }
  return (
    <div className="flex grow flex-col justify-between">
      <BaseLabels
        labels={EVENT_LABELS}
        labelText={EVENT_LABEL_TEXT}
        labelDescription={EVENT_LABEL_DESCRIPTION}
        selectedLabels={selectedLabel ? [selectedLabel] : []}
        onLabelClick={handleLabelClick}
        showDescriptions={showDescriptions}
      />
      <div className="mt-2 flex grow justify-center font-bold">
        <TimeStampContainer range={range} />
      </div>
    </div>
  )
}
