import {
  AddEventsToLabelingQueueParameters,
  Event,
  EventLabels,
  Events,
  Phase,
} from 'common/types'
import { extractSerialInstant, getInstantKey } from 'common/utils'
import { ref } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import {
  flagKey,
  markEntryAsLabeled,
  QueueEntry,
  useQueue,
} from 'shared/hooks/useQueue'
import { dataState, loadingState } from 'shared/types/asyncState'
import { FirebaseKey } from 'shared/types/utils'
import { fetch_ } from 'shared/utils/fetch'
import { onError } from 'shared/utils/web/error'
import { auth, database } from '../firebase'
import { remove, set, update } from '../firebaseMethods'

interface EventFromURL {
  sequence: Event
  sequenceKey: FirebaseKey
  sequenceIndex?: number
}

type UseEvent = {
  eventKey: string
  serial: string
  eventSeed: string // ISO 8601
  eventStart: string // ISO 8601
  eventEnd: string // ISO 8601
  saveLabels: (labels: Phase[]) => Promise<void>
  flagCurrentKey: () => Promise<void>
} | null

async function getEventsFromURL() {
  const urlParameter = window.location.pathname.replace(/^\//, '')
  const serialInstant = extractSerialInstant(urlParameter)

  if (serialInstant === undefined) return null

  const source = 'link'
  const why = 'unknown'
  const priority = 1
  const labelersTarget = 3

  const requestBody: AddEventsToLabelingQueueParameters = [
    {
      instants: [`${serialInstant.serial}/${serialInstant.instant}`],
      source: source,
      why: why,
      priority: priority,
      labelersTarget: labelersTarget,
    },
  ]

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }

  const events = await fetch_(
    `https://europe-west1-${
      import.meta.env.VITE_PROJECT_ID
    }.cloudfunctions.net/addEventsToLabelingQueue`,
    options,
  )
    .then((response) => response.json())
    .then((result) => result as Events)

  const sequenceKey = Object.keys(events)[0]
  const sequence = events[sequenceKey]

  return { sequence, sequenceKey }
}

// Warning, must be defined out of useSequence to provide a stable reference to useQueue
// Otherwise results in an infinite re-render loop
const eventsRef = ref(database, 'events')

export const useEvent = () => {
  const userId = auth.currentUser?.uid ?? 'unknown'

  // undefined = no yet set
  // null = no valid data found in URL
  const [eventFromURL, setEventFromURL] = useState<
    EventFromURL | null | undefined
  >(undefined)

  const queue = useQueue<Event>(userId, eventsRef)

  const [startTime, setStartTime] = useState(0)

  const [isUpdating, setIsUpdating] = useState(false)

  let currentEvent: QueueEntry<Event> | undefined = undefined

  // Wait for URL to be parsed, otherwise queue item is used
  if (eventFromURL !== undefined) {
    // In case a URL is in use
    if (eventFromURL) {
      currentEvent = {
        key: eventFromURL.sequenceKey,
        value: eventFromURL.sequence,
      }
    } else {
      if (!queue.loading && queue.data !== null) {
        currentEvent = queue.data.head
      }
    }
  }

  // Trigger a single url parsing on start
  useEffect(() => {
    async function runEffect() {
      const newEventsFromURL = await getEventsFromURL()
      setEventFromURL(newEventsFromURL)
    }

    runEffect()
  }, [])

  // Re-init start time on each event change
  useEffect(() => {
    setStartTime(Date.now())
  }, [currentEvent?.key])

  const saveLabels = useCallback(
    async (labels: Phase[]) => {
      if (!currentEvent) throw Error('saveLabels called with null event')

      const { serial, source, why, eventSeed, eventStart, eventEnd } =
        currentEvent.value

      const key = getInstantKey(serial, eventSeed)

      const eventLabels: EventLabels = {
        ts: Date.now(), // to keep it consistent with startTime which is evaluated locally
        start_ts: startTime,
        labels: labels.sort((labelA, labelB) => {
          return labelA.label.localeCompare(labelB.label)
        }),
      }

      // Copy data to export from 'events' to 'labeledEvents', needed even if it's not the first labeling
      const labeledEvent = {
        serial,
        eventSeed,
        eventStart,
        eventEnd,
        source,
        why,
      }

      setIsUpdating(true)
      try {
        await update(`labeledEvents/${key}`, labeledEvent)

        await set(`labeledEvents/${key}/labelers/${userId}`, eventLabels)

        await markEntryAsLabeled(eventsRef, currentEvent.key, userId, () => {
          return Promise.all([
            remove(`events/${currentEvent.key}`),
            set(`eventsToBeExported/${currentEvent.key}`, true as const),
          ])
        })

        // Trigger selection of a new event
        if (eventFromURL) setEventFromURL(null)
        else {
          if (queue.loading || queue.data === null) return
          queue.data.pop()
        }
      } catch (error) {
        onError(error)
      } finally {
        setIsUpdating(false)
      }
    },
    [currentEvent, queue.data, queue.loading, startTime, eventFromURL, userId],
  )

  const flagCurrentKey = useCallback(async () => {
    if (!currentEvent) throw Error('flagCurrentKey called with no key')

    setIsUpdating(true)
    try {
      // First set the flag
      await flagKey(eventsRef, currentEvent.key, userId)

      // Then trigger the queue update
      if (queue.data) {
        queue.data.pop()
      }
    } finally {
      setIsUpdating(false)
    }
  }, [currentEvent, queue.data, userId])

  if (queue.loading || eventFromURL === undefined || isUpdating)
    return loadingState()

  if (currentEvent)
    return dataState<UseEvent>({
      eventKey: currentEvent.key,
      serial: currentEvent.value.serial,
      eventSeed: currentEvent.value.eventSeed,
      eventStart: currentEvent.value.eventStart,
      eventEnd: currentEvent.value.eventEnd,
      saveLabels,
      flagCurrentKey,
    })

  return dataState<UseEvent>(null)
}
