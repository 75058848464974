import {
  AddInstantsToLabelingQueueParameters,
  InstantLabels,
  SCOPES,
  Sequence,
  Sequences,
} from 'common/types'
import {
  SCOPE_AVG_STEP_MAPPING,
  extractSerialInstant,
  getInstantKey,
} from 'common/utils'
import { ref } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import {
  QueueEntry,
  flagKey,
  markEntryAsLabeled,
  useQueue,
} from 'shared/hooks/useQueue'
import { dataState, loadingState } from 'shared/types/asyncState'
import { FirebaseKey } from 'shared/types/utils'
import { fetch_ } from 'shared/utils/fetch'
import { onError } from 'shared/utils/web/error'
import { z } from 'zod'
import { auth, database } from '../firebase'
import { get, remove, set, update } from '../firebaseMethods'

interface SequenceFromURL {
  sequence: Sequence
  sequenceKey: FirebaseKey
  sequenceIndex?: number
}

type UseSequence = {
  sequenceKey: string
  serial: string
  instants: string[]
  sequenceIndex: number
  hint: string | undefined
  saveLabels: (labels: string[]) => Promise<void>
  flagCurrentKey: () => Promise<void>
} | null

async function getSequencesFromURL() {
  const urlParameter = window.location.pathname.replace(/^\//, '')
  const serialInstant = extractSerialInstant(urlParameter)

  if (serialInstant === undefined) return null

  const params = new URLSearchParams(window.location.search)
  const scope = z.enum(SCOPES).nullable().parse(params.get('scope'))

  const source = 'link'
  const why = 'unknown'
  const priority = 1
  const labelersTarget = 3
  const addRandomOffsetToRef = false

  const requestBody: AddInstantsToLabelingQueueParameters = [
    {
      instants: [`${serialInstant.serial}/${serialInstant.instant}`],
      source: source,
      why: why,
      priority: priority,
      labelersTarget: labelersTarget,
      avgStep: SCOPE_AVG_STEP_MAPPING[scope ?? 'mid'],
      addRandomOffsetToRef: addRandomOffsetToRef,
    },
  ]

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }

  const sequences = await fetch_(
    `https://europe-west1-${
      import.meta.env.VITE_PROJECT_ID
    }.cloudfunctions.net/addInstantsToLabelingQueue`,
    options,
  )
    .then((response) => response.json())
    .then((result) => result as Sequences)

  const sequenceKey = Object.keys(sequences)[0]
  const sequence = sequences[sequenceKey]

  return { sequence, sequenceKey }
}

async function getInstantFromURL() {
  const parts = window.location.pathname.split('/')
  if (parts.length !== 3) return null
  const [_, sequenceKey, sequenceIndexStr] = parts
  if (!/[A-Za-z0-9]+_[a-z0-9]{16}-[0-9T:+-]+/.test(sequenceKey)) return null
  const sequenceIndex = Number(sequenceIndexStr)
  if (isNaN(sequenceIndex)) return null

  const sequence = await get(`sequences/${sequenceKey}`)

  return { sequence, sequenceKey, sequenceIndex }
}

// Warning, must be defined out of useSequence to provide a stable reference to useQueue
// Otherwise results in an infinite re-render loop
const sequencesRef = ref(database, 'sequences')

export const useSequence = () => {
  const userId = auth.currentUser?.uid ?? 'unknown'

  // undefined = no yet set
  // null = no valid data found in URL
  const [sequenceFromURL, setSequenceFromURL] = useState<
    SequenceFromURL | null | undefined
  >(undefined)

  const [instantFromURL, setInstantFromURL] = useState<
    SequenceFromURL | null | undefined
  >(undefined)

  const queue = useQueue<Sequence>(userId, sequencesRef)

  const [isUpdating, setIsUpdating] = useState(false)

  // Instant index in sequence
  const [sequenceIndex, setSequenceIndex] = useState(0)

  const [startTime, setStartTime] = useState(0)

  let currentSequence: QueueEntry<Sequence> | undefined = undefined

  // Wait for URL to be parsed, otherwise queue item is used
  if (sequenceFromURL !== undefined && instantFromURL !== undefined) {
    // In case a URL is in use
    const urlSequence = sequenceFromURL ?? instantFromURL
    if (urlSequence) {
      currentSequence = {
        key: urlSequence.sequenceKey,
        value: urlSequence.sequence,
      }
    } else {
      if (!queue.loading && queue.data !== null) {
        currentSequence = queue.data.head
      }
    }
  }

  // Trigger a single url parsing on start
  useEffect(() => {
    async function runEffect() {
      const sequencesFromURL = await getSequencesFromURL()
      setSequenceFromURL(sequencesFromURL)
    }

    runEffect()
  }, [])

  useEffect(() => {
    async function runEffect() {
      const instantFromUrl = await getInstantFromURL()
      setInstantFromURL(instantFromUrl)
      if (instantFromUrl !== null) {
        setSequenceIndex(instantFromUrl.sequenceIndex)
      }
    }

    runEffect()
  }, [])

  // Re-init start time on each instant change
  useEffect(() => {
    setStartTime(Date.now())
  }, [sequenceIndex])

  const saveLabels = useCallback(
    async (labels: string[]) => {
      if (!currentSequence) throw Error('saveLabels called with no sequence')

      const { serial, source, why, instants } = currentSequence.value

      const instant = instants[sequenceIndex]
      const key = getInstantKey(serial, instant)

      const instantLabels: InstantLabels = {
        ts: Date.now(), // to keep it consistent with startTime which is evaluated locally
        start_ts: startTime,
        labels: labels.sort((labelA, labelB) => {
          return labelA.localeCompare(labelB)
        }),
      }

      setIsUpdating(true)
      try {
        // Copy data to export from 'sequences' to 'labeledInstants', needed even if it's not the first labeling
        const labeledInstant = {
          serial,
          source,
          why,
          instant,
        }
        await update(`labeledInstants/${key}`, labeledInstant)

        await set(`labeledInstants/${key}/labelers/${userId}`, instantLabels)

        // Select next instant in sequence
        // Warning setSequenceIndex is async, compute actual value
        const nextSequenceIndex = sequenceIndex + 1

        if (nextSequenceIndex < instants.length) {
          setSequenceIndex(nextSequenceIndex)
        } else {
          await markEntryAsLabeled(
            sequencesRef,
            currentSequence.key,
            userId,
            () => {
              return Promise.all([
                remove(`sequences/${currentSequence.key}`),
                ...currentSequence.value.instants
                  .map((instant) => getInstantKey(serial, instant))
                  .map((key) =>
                    set(`instantsToBeExported/${key}`, true as const),
                  ),
              ])
            },
          )

          // Trigger selection of a new sequence
          if (sequenceFromURL) setSequenceFromURL(null)
          else if (instantFromURL) setInstantFromURL(null)
          else {
            if (queue.loading || queue.data === null) return
            queue.data.pop()
          }

          setSequenceIndex(0)
        }
      } catch (error) {
        onError(error)
      } finally {
        setIsUpdating(false)
      }
    },
    [
      currentSequence,
      sequenceIndex,
      startTime,
      userId,
      sequenceFromURL,
      instantFromURL,
      queue,
    ],
  )

  const flagCurrentKey = useCallback(async () => {
    if (!currentSequence) throw Error('flagCurrentKey called with no key')

    setIsUpdating(true)
    try {
      // First set the flag
      await flagKey(sequencesRef, currentSequence.key, userId)

      // Reset sequence index
      setSequenceIndex(0)

      // Then trigger the queue update
      if (queue.data) {
        queue.data.pop()
      }
    } finally {
      setIsUpdating(false)
    }
  }, [currentSequence, queue.data, userId])

  if (
    queue.loading ||
    sequenceFromURL === undefined ||
    instantFromURL === undefined ||
    isUpdating
  )
    return loadingState()

  if (currentSequence)
    return dataState<UseSequence>({
      sequenceKey: currentSequence.key,
      serial: currentSequence.value.serial,
      instants: currentSequence.value.instants,
      hint: currentSequence.value.hint,
      sequenceIndex,
      saveLabels,
      flagCurrentKey,
    })

  return dataState<UseSequence>(null)
}
