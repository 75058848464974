import { Dialog as SharedDialog } from 'shared/components/Dialog'
import { Button } from './Button'

type DialogProps = {
  onClose: () => void
  onValidate: () => void
  children: React.ReactNode
  submitButtonProps: { text: string; primary?: boolean; className?: string }
}
export const Dialog = ({
  onClose,
  onValidate,
  children,
  submitButtonProps,
}: DialogProps) => {
  function handleValidate() {
    onValidate()
    onClose()
  }

  return (
    <SharedDialog onClose={onClose}>
      <div className="bg-background flex flex-col gap-4 rounded-md p-4">
        {children}
        <div className="flex flex-row justify-evenly gap-2">
          <Button onClick={onClose}>Annuler</Button>
          <Button {...submitButtonProps} onClick={handleValidate}>
            {submitButtonProps.text}
          </Button>
        </div>
      </div>
    </SharedDialog>
  )
}
