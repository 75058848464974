import { LABEL_EVENT_TYPE } from 'common/ontology'
import { useEffect, useMemo, useState } from 'react'
import { Button } from '../../components/Button'
import { Dialog } from '../../components/Dialog'
import { ReportAction } from '../../components/Report'
import { useEvent } from '../../hooks/useEvent'
import { EventLabels } from './EventLabels'
import { EventProvider, useEventContext } from './EventProvider'
import { EventTimeLine } from './EventTimeline'

const ShowDescriptionStorageKey = 'showDescriptions'

const EventMainPageContent = () => {
  const {
    setSelectedLabel,
    editedPhase,
    setEditedPhase,
    errorMessage,
    selectedLabel,
  } = useEventContext()

  const [isValidationDialogOpen, setValidationDialogOpen] =
    useState<boolean>(false)
  const [showDescriptions, setShowDescriptions] = useState<boolean>(
    JSON.parse(localStorage.getItem(ShowDescriptionStorageKey) ?? 'true'),
  )

  const asyncEvent = useEvent()

  // Reset selected labels on instant index change
  useEffect(() => {
    setSelectedLabel(null)
    setEditedPhase(null)
  }, [asyncEvent.data?.eventKey, setSelectedLabel, setEditedPhase])

  const allowValidation = useMemo(() => {
    return (
      selectedLabel &&
      (editedPhase?.start !== undefined ||
        editedPhase?.low !== undefined ||
        editedPhase?.medium !== undefined ||
        editedPhase?.high !== undefined)
    )
  }, [
    editedPhase?.high,
    editedPhase?.low,
    editedPhase?.medium,
    editedPhase?.start,
    selectedLabel,
  ])

  const disabled = useMemo(() => {
    if (selectedLabel === null) return false

    if (LABEL_EVENT_TYPE[selectedLabel] === 'abruptStart') {
      return editedPhase?.start === undefined || editedPhase?.end === undefined
    } else {
      return (
        editedPhase?.end === undefined ||
        editedPhase?.low === undefined ||
        editedPhase?.medium === undefined ||
        editedPhase?.high === undefined
      )
    }
  }, [editedPhase, selectedLabel])

  // change URL on instant change
  useEffect(() => {
    if (asyncEvent.loading || asyncEvent.error || asyncEvent.data === null)
      return

    const { serial, eventSeed } = asyncEvent.data

    window.history.replaceState(
      null,
      '',
      `${window.location.origin}/${serial}/${eventSeed}`,
    )
  }, [asyncEvent])

  function toggleShowDescription() {
    setShowDescriptions((value) => !value)
    localStorage.setItem(
      ShowDescriptionStorageKey,
      JSON.stringify(!showDescriptions),
    )
  }

  function onSubmit() {
    saveLabels(editedPhase ? [editedPhase] : [])
  }

  if (asyncEvent.loading)
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Chargement...
      </div>
    )

  if (asyncEvent.data === null)
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        Pas d'études à labeliser
      </div>
    )

  const { serial, eventStart, eventEnd, saveLabels, eventKey, flagCurrentKey } =
    asyncEvent.data

  return (
    <div className="relative flex h-screen flex-col">
      <EventTimeLine
        key={eventKey} // Force TimeLine re-rendering, otherwise strange behavior when starting a new event after use of eventFromURL
        serial={serial}
        eventStart={eventStart}
        eventEnd={eventEnd}
      />
      <EventLabels
        showDescriptions={showDescriptions}
        range={{ start: eventStart, end: eventEnd }}
      />
      <div className="flex flex-row items-center gap-4 px-4 py-2">
        <Button title="Voir la page d'aide">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/osoai/Page-d-aide-Int-grateur-70032ca9f7fc4b15b40f34b07ce12962"
          >
            <b>?</b>
          </a>
        </Button>
        <ReportAction onReport={flagCurrentKey} />
        <Button
          title="Enregistrer et passer au suivant"
          primary={true}
          disabled={errorMessage.length > 0 || disabled}
          className="flex-1"
          onClick={() => {
            if (!allowValidation) return setValidationDialogOpen(true)

            onSubmit()
          }}
        >
          Valider
        </Button>
        <Button
          title="Afficher la description des labels"
          onClick={toggleShowDescription}
        >
          {showDescriptions ? '⎕' : '⌧'}
        </Button>
      </div>
      {isValidationDialogOpen && (
        <Dialog
          onClose={() => setValidationDialogOpen(false)}
          onValidate={() => onSubmit()}
          submitButtonProps={{ text: 'Valider', primary: true }}
        >
          <p>
            Vous n’avez pas sélectionné de label. Vous estimez qu’aucun label ne
            correspond à la situation.
          </p>
          <p>Voulez-vous continuer ?</p>
        </Dialog>
      )}
    </div>
  )
}

export const EventMainPage = () => {
  return (
    <EventProvider>
      <EventMainPageContent />
    </EventProvider>
  )
}
