export type FacilityId = string // FirebaseKey used for facilities

export type FacilityName = string // facility display name
// Should be used in place of FacilityId in reporting and other places

export type Serial = string

export type FirebaseKey = string

export type UserId = string // As set by Firebase auth

export type Room = string

export type DateString = string // YYYY-MM-DD format

export const REPORT_KEY_FLAG = 'REPORT_KEY_FLAG'

export type Flag = {
  type: 'REPORT_KEY_FLAG'
  createdAt: number
  userId: UserId
}
