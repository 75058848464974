import { useState } from 'react'
import { Button } from './Button'
import { Dialog } from './Dialog'

export const ReportAction = ({ onReport }: { onReport: () => void }) => {
  const [isReportDialogOpen, setReportDialogOpen] = useState<boolean>(false)

  function onSubmit() {
    onReport()
    setReportDialogOpen(false)
  }

  return (
    <>
      <Button
        title="Signaler la séquence"
        className="bg-red-500 hover:bg-red-500/90"
        onClick={() => setReportDialogOpen(true)}
      >
        Signaler
      </Button>

      {isReportDialogOpen && (
        <Dialog
          onClose={() => setReportDialogOpen(false)}
          onValidate={() => onSubmit()}
          submitButtonProps={{
            text: 'Confirmer le signalement',
            className: 'bg-red-500 hover:bg-red-500/90',
          }}
        >
          <b>Signaler une séquence sonore</b>
          <p>Êtes-vous sûr(e) de vouloir signaler cette séquence sonore ?</p>
          <p>
            Elle sera retirée de la file d'attente et envoyée aux
            administrateurs pour information.
          </p>
          <b>Cette action est irréversible.</b>
        </Dialog>
      )}
    </>
  )
}
