import { EventLabel, LABEL_EVENT_TYPE } from 'common/ontology'
import { Phase } from 'common/types'
import { DateTime } from 'luxon'
import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

type EventContextType = {
  selectedLabel: EventLabel | null
  setSelectedLabel: (label: EventLabel | null) => void
  editedPhase: Phase | null
  setEditedPhase: (phase: Phase | null) => void
  selectedTimestamp: string | null
  setSelectedTimestamp: (timestamp: string | null) => void // ISO 8601
  handleKeyPressed: (keyboardEvent: KeyboardEvent) => void
  errorMessage: string
}

const EventContext = createContext<EventContextType | null>(null)

export const EventProvider = ({ children }: { children: ReactNode }) => {
  const [selectedLabel, setSelectedLabel] = useState<EventLabel | null>(null)
  const [editedPhase, setEditedPhase] = useState<Phase | null>(null)
  const [selectedTimestamp, setSelectedTimestamp] = useState<string | null>(
    null,
  )

  function handleKeyPressed(keyboardEvent: KeyboardEvent) {
    if (!selectedLabel) return
    if (!selectedTimestamp) return

    switch (keyboardEvent.key.toLowerCase()) {
      case 'd':
        if (LABEL_EVENT_TYPE[selectedLabel] !== 'abruptStart') break
        setEditedPhase({
          ...editedPhase,
          start: selectedTimestamp,
          label: selectedLabel,
        })
        break
      case 'a':
        if (LABEL_EVENT_TYPE[selectedLabel] !== 'gradualStart') break
        setEditedPhase({
          ...editedPhase,
          low: selectedTimestamp,
          label: selectedLabel,
        })
        break
      case 'z':
        if (LABEL_EVENT_TYPE[selectedLabel] !== 'gradualStart' || !editedPhase)
          break
        setEditedPhase({
          ...editedPhase,
          medium: selectedTimestamp,
        })
        break
      case 'e':
        if (LABEL_EVENT_TYPE[selectedLabel] !== 'gradualStart' || !editedPhase)
          break
        setEditedPhase({
          ...editedPhase,
          high: selectedTimestamp,
        })
        break
      case 'f':
        if (!editedPhase) return
        setEditedPhase({
          ...editedPhase,
          end: selectedTimestamp,
        })
        break
    }
  }

  const errorMessage = useMemo(() => {
    if (!editedPhase) return ''

    const { start, low, medium, high, end } = editedPhase

    const isBeforeOrUndefined = (
      a?: string | 'before', // ISO 8601
      b?: string | 'before' | 'after', // ISO 8601
    ): boolean => {
      if (a === undefined || b === undefined) return true
      if (b === 'after') return true
      if (a === 'before') return true
      if (b === 'before') return false

      const dateA = DateTime.fromISO(a)
      const dateB = DateTime.fromISO(b)

      if (!dateA.isValid || !dateB.isValid) {
        throw new Error('Invalid ISO 8601 date string')
      }

      return dateA < dateB
    }

    if (start) {
      return !isBeforeOrUndefined(start, end)
        ? 'Erreur : le début est avant la fin'
        : ''
    }

    const errors: string[] = []

    if (!isBeforeOrUndefined(low, medium)) errors.push('Faible/Moyen incorrect')
    if (!isBeforeOrUndefined(medium, high)) errors.push('Moyen/Elevé incorrect')
    if (!isBeforeOrUndefined(high, end)) errors.push('Elevé/Fin incorrect')

    return errors.length > 0 ? `Erreur : ${errors.join(' - ')}` : ''
  }, [editedPhase])

  const value = {
    selectedLabel,
    setSelectedLabel,
    editedPhase,
    setEditedPhase,
    handleKeyPressed,
    selectedTimestamp,
    setSelectedTimestamp,
    errorMessage,
  }

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}

export const useEventContext = () => {
  const context = useContext(EventContext)
  if (!context) {
    throw new Error('useEventContext must be used within an EventProvider')
  }
  return context
}
