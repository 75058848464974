import { DateTime, Zone } from 'luxon'
import { useCallback } from 'react'
import { timeout } from 'shared/utils/function/timeout'
import { ms } from 'shared/utils/time'
import { useSettings } from '../SettingsProvider'

export const MAX_DURATION = ms(120, 'minutes')

export type SequenceAdditionnalMediaControllerProps = {
  instants: string[]
  sequenceIndex: number
  handleIncreaseDuration: () => void
}

type Props = {
  start: number
  end: number
  zone: Zone
  scrollRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  percent: number
  isPlaying: boolean
  togglePlayPause: () => void
  currentAudioElement: HTMLAudioElement | null
  setDisplayLabelDialog: React.Dispatch<React.SetStateAction<boolean>>
  sequenceProps?: SequenceAdditionnalMediaControllerProps | undefined
  isScaleEnabled: boolean
}

export const MediaController = ({
  start,
  end,
  zone,
  scrollRef,
  containerRef,
  percent,
  isPlaying,
  togglePlayPause,
  currentAudioElement,
  setDisplayLabelDialog,
  sequenceProps,
  isScaleEnabled,
}: Props) => {
  const {
    contrast,
    setContrast,
    scale,
    setScale,
    playbackRate,
    setPlaybackRate,
  } = useSettings()

  const handlePlaybackRateClick = useCallback(() => {
    const playbackRates = [1, 1.25, 1.5, 2, 3]
    setPlaybackRate((prevPlaybackRate) => {
      return playbackRates[
        (playbackRates.indexOf(prevPlaybackRate) + 1) % playbackRates.length
      ]
    })
  }, [setPlaybackRate])

  function setScrollLeft(scroll: number) {
    // Must be done after repaint and new scale is applied, so that scroll value
    // can be applied, taking new size into account
    timeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = scroll
      }
    }, 0)
  }

  function scaleBy(coef: number) {
    if (!containerRef.current || !scrollRef.current) return

    const newScale = Math.max(1, Math.min(20, scale * coef))

    const containerWidth = containerRef.current.getBoundingClientRect().width

    const scrollLeft = scrollRef.current.scrollLeft
    const headPos = scale * containerWidth * (percent / 100) - scrollLeft

    if (headPos >= 0 && headPos <= containerWidth) {
      // Keep head pos at the same position
      setScrollLeft(
        scrollLeft + (newScale - scale) * (percent / 100) * containerWidth,
      )
    } else {
      // Zoom on middle pixel
      const middlePixel = scrollLeft + containerWidth / 2
      setScrollLeft((newScale / scale) * middlePixel - containerWidth / 2)
    }

    setScale(newScale)
  }

  return (
    <div className="flex flex-row justify-between">
      {sequenceProps !== undefined && (
        <button
          className="rounded-md bg-blue-500 px-2 pt-0.5 hover:bg-blue-700 disabled:opacity-30"
          disabled={end - start >= MAX_DURATION}
          onClick={sequenceProps.handleIncreaseDuration}
          title="Voir les sons précédents"
        >
          ⬅
        </button>
      )}
      <div className="flex flex-row items-baseline gap-3">
        <div
          className="cursor-pointer select-none rounded-md bg-blue-500 px-2 font-semibold hover:bg-blue-700"
          onClick={handlePlaybackRateClick}
          title="Vitesse de lecture"
        >
          {playbackRate}x
        </div>
        <div
          className="flex w-7 cursor-pointer select-none flex-col items-center justify-center rounded-full bg-blue-500 p-2 hover:bg-blue-700"
          onMouseUp={togglePlayPause}
          title="Lecture / pause"
        >
          <svg viewBox="0 0 60 60" fill="#ffffff">
            {isPlaying ? (
              <>
                <polygon points="10,5 25,5 25,55 10,55" />
                <polygon points="35,5 50,5 50,55 35,55" />
              </>
            ) : (
              <polygon points="10,0 60,30 10,60" />
            )}
          </svg>
        </div>
        <div className="tabular-nums">
          {DateTime.fromMillis(start + ((end - start) * percent) / 100, {
            zone,
          }).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
        </div>
      </div>
      {isScaleEnabled && (
        <div className="flex flex-row gap-2">
          <div
            className="flex h-7 w-7 cursor-pointer select-none flex-col items-center justify-center rounded-full bg-blue-500 text-xl font-semibold hover:bg-blue-700"
            onClick={() => scaleBy(0.8)}
            title="Dézoomer"
          >
            －
          </div>
          <div
            className="flex h-7 w-7 cursor-pointer select-none flex-col items-center justify-center rounded-full bg-blue-500 text-xl font-semibold hover:bg-blue-700"
            onClick={() => scaleBy(1.2)}
            title="Zoomer"
          >
            ＋
          </div>
        </div>
      )}
      <input
        type="range"
        value={contrast}
        onChange={(e) => setContrast(parseInt(e.target.value))}
        title="Réglage de la sensibilité"
      />
      <button
        className="rounded-full bg-violet-500 px-1.5 pt-0.5 hover:bg-violet-700 disabled:opacity-30"
        disabled={currentAudioElement === null}
        onClick={() => {
          currentAudioElement?.pause()
          setDisplayLabelDialog((value) => !value)
        }}
        title="Labeliser ce son"
      >
        🏷
      </button>
      {sequenceProps !== undefined && (
        <div title="Index dans la séquence">
          {sequenceProps.sequenceIndex + 1}/{sequenceProps.instants.length}
        </div>
      )}
    </div>
  )
}
