import { computePercent } from 'common/utils'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { dateTimeFromISO, ms } from 'shared/utils/time'
import { defaultMetrics } from 'shared/utils/web/metrics'
import { BaseTimeline } from '../../components/BaseTimeline'
import { MAX_DURATION } from '../../components/MediaController'

const INITIAL_DURATION = ms(10, 'minutes')
const DURATION_DELTA = ms(5, 'minutes')

type SequenceTimeLineProps = {
  sequenceKey: string
  serial: string
  instants: string[]
  sequenceIndex: number
}

const TimeLine: React.FC<SequenceTimeLineProps> = ({
  sequenceKey,
  serial,
  instants,
  sequenceIndex,
}) => {
  const referenceInstant = React.useMemo(
    () =>
      new Date(sequenceKey.substring(sequenceKey.indexOf('-') + 1)).valueOf(),
    [sequenceKey],
  )

  const [startDate, setStartDate] = useState(
    dateTimeFromISO(instants[0])
      .minus({ milliseconds: INITIAL_DURATION })
      .startOf('minute'), // since fetchData has a minute resolution
  )

  const [endDate, setEndDate] = useState(
    dateTimeFromISO(instants[sequenceIndex]),
  )

  const start = React.useMemo(() => startDate.valueOf(), [startDate])
  const end = React.useMemo(() => endDate.valueOf(), [endDate])

  function handleIncreaseDuration() {
    const newStart = startDate.minus({ milliseconds: DURATION_DELTA })
    const startLimit = endDate.minus({ milliseconds: MAX_DURATION })
    setStartDate(newStart > startLimit ? newStart : startLimit)
  }

  // Extend end when sequenceIndex changes
  useEffect(() => {
    setEndDate(dateTimeFromISO(instants[sequenceIndex]))
  }, [instants, sequenceIndex])

  const renderAdditionalElements = React.useCallback(
    () => (
      <>
        {instants.slice(0, sequenceIndex).map((instant) => (
          <div
            key={instant}
            className="absolute inset-y-0 -ml-0.5 w-0.5 bg-rose-600 bg-opacity-50"
            style={{
              left: `${computePercent(dateTimeFromISO(instant).valueOf(), start, end)}%`,
            }}
            title="Instant précédemment labellisé"
          />
        ))}
        <div
          className="absolute inset-y-0 -ml-0.5 w-0.5 bg-rose-600"
          style={{
            left: `100%`,
          }}
          title="Instant à labelliser"
        />
        {referenceInstant <=
          dateTimeFromISO(instants[sequenceIndex]).valueOf() && (
          <div
            className="absolute inset-y-0 -ml-1 w-0.5 bg-amber-400"
            style={{
              left: `${computePercent(referenceInstant, start, end)}%`,
            }}
            title="Instant de référence"
          />
        )}
      </>
    ),
    [start, end, instants, sequenceIndex, referenceInstant],
  )

  return (
    <BaseTimeline
      isScaleEnabled
      serial={serial}
      startDate={startDate}
      endDate={endDate}
      metrics={defaultMetrics}
      renderAdditionalElements={renderAdditionalElements}
      sequenceProps={{ handleIncreaseDuration, sequenceIndex, instants }}
      adjustEnd={(newEnd: DateTime) => setEndDate(newEnd)}
    />
  )
}

export const SequenceTimeLine = React.memo(TimeLine)
